// data fetched from the api
interface Track {
    id: string;
    title: string;
    visibility: boolean;
    install: boolean;
}

interface ReturnTrack {
    id: string;
    visibility: boolean;
    install: boolean;
}

interface Artist {
    id: string;
    name: string;
    tracks: Track[];
}

// data to be sent back to the api
interface ReturnArtist {
    id: string;
    tracks: ReturnTrack[];
}

interface Data {
    artists: Artist[];
}

interface ReturnData {
    artists: ReturnArtist[];
}

// device status fetched from the api

interface DeviceTrack {
    name: string;
    installed: boolean;
    install: boolean;
    status: string;
    id: string;
}

interface DeviceArtist {
    tracks: DeviceTrack[];
    id: string;
    name: string;
}

export const DeviceState = {
    COMPLETE: "Complete",
    PENDING: "Incomplete",
    ERROR: "Error",
} as const;

interface Device {
    name: string;
    id: string;
    artists: DeviceArtist[];
    last_updated_utc?: string;
    status: DeviceState;
}

type DevicesResponse = Device[];

export type { Data, ReturnData, Artist, ReturnArtist, Track, ReturnTrack, DeviceTrack, DeviceArtist, Device, DevicesResponse }
export type DeviceState = typeof DeviceState[keyof typeof DeviceState]; // eslint-disable-line

