import { useState, useEffect } from 'react';
import { signIn, signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes, updateUserAttributes } from 'aws-amplify/auth';
import '.././amplifyConfig';


/**
 * Custom hook for handling authentication logic.
 * 
 * @returns {Object} - An object containing authentication state and handlers.
 */
function useAuth() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [signInAllowed, setSignInAllowed] = useState(true);
    const [errorValue, setErrorValue] = useState("");
    const [signedIn, setSignedIn] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [soapboxData, setSoapboxData] = useState(null);
    const [idToken, setIDToken] = useState("");
    const [institution, setInstitution] = useState("");
    const [utcTimeEpoch, setUtcTimeEpoch] = useState(0);
    const [userAcceptedTcVersion, setUserAcceptedTcVersion] = useState("not-fetched");
    const latestTCVersion = "1.2";
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    async function getUserAttributes() {
        try {
            const userAttributes = await fetchUserAttributes();
            const userLatestTcVersion = userAttributes['custom:tcVersion'];
            // console.log("tcVersion: ", userLatestTcVersion, "latestTCVersion: ", latestTCVersion);
            if (userLatestTcVersion) {
                setUserAcceptedTcVersion(userLatestTcVersion);
            }
        } catch (error) {
            console.error('Error fetching user attributes:', error);
        }
    }

    async function handleAcceptTerms() {
        try {
            await updateUserAttributes({
                userAttributes: {
                    'custom:tcVersion': latestTCVersion,
                },
            });
            setUserAcceptedTcVersion(latestTCVersion);
        } catch (error) {
            console.error('Error updating user attributes:', error);
        }
    }

    useEffect(() => {
        setShowTermsAndConditions(userAcceptedTcVersion !== latestTCVersion && userAcceptedTcVersion !== "not-fetched");
    }, [userAcceptedTcVersion, latestTCVersion,]);

    useEffect(() => {
        if (username !== "" && password !== "") setSignInAllowed(false);
        else setSignInAllowed(true);
    }, [username, password]);

    /**
    * Effect to fetch the main dashboard content if the user is signed in and content is not already fetched.
    */
    useEffect(() => {
        console.log("ID Token: ", idToken.substring(0, 20) + "...");
        // CheckCurrentSession();
        if (signedIn && idToken) {
            getUserAttributes();
            GetContent();
        }
    }, [signedIn, idToken]);

    useEffect(() => {
        CheckCurrentSession();
    }, []);

    async function handleSignIn(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setSigningIn(true);
        setSignInAllowed(false);
        setErrorValue("");
        const data = new FormData(event.currentTarget);
        let login = signIn({
            username: String(data.get("username")),
            password: String(data.get("password")),
            options: {
                authFlowType: 'USER_SRP_AUTH'
            }
        });
        login.then(
            async function (value) {
                const session = await fetchAuthSession();
                var idToken = String(session?.tokens?.idToken?.toString());
                setIDToken(idToken);
                const currentUser = await getCurrentUser();
                setUsername(currentUser.username);
                getUserAttributes();
                setSigningIn(false);
                setSignedIn(true);
            },
            function (error) {
                console.error(error);
                setErrorValue(error.message);
                setSigningIn(false);
                setSignInAllowed(true);
            }
        );
    };

    async function handleSignOut(event: React.FormEvent<HTMLFormElement>) {
        setSignedIn(false);
        event.preventDefault();
        setErrorValue("");
        setSoapboxData(null);
        await signOut();
        setSignedIn(false);
    };

    /**
     * Checks the current user session from Amplify Auth. 
     * Aligns the signedIn state with the current Auth session.
     */
    async function CheckCurrentSession() {
        try {
            const session = await fetchAuthSession();
            const idToken = String(session?.tokens?.idToken?.toString());
            const currentUser = await getCurrentUser();
            setIDToken(idToken);
            setSignedIn(true);
            setUsername(currentUser.username);
        } catch (error) {
            setSignedIn(false);
        }
    }

    /**
     * Fetches the education tracks content distribution from the Soapbox API.
     */
    async function GetContent() {
        
        const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/content`;
        try {
            const response = await fetch(url, {
                headers: {
                    "Authorization": idToken
                }
            });
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            let data = await response.json();
            setSoapboxData(data.content);
            setInstitution(data.institution);
            setUtcTimeEpoch(data.utc);
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    }

    async function setTrackInstallAndVisibility(pdata: any): Promise<any> {
        const url = `https://${process.env.REACT_APP_ENV_STAGE}.soapboxapis.com/education/v3/content`;
        const content = JSON.stringify(pdata.artists, null, 2);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": idToken,
                    "Content-Type": "application/json"
                },
                body: content
            });
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }
            let data = await response.json();
            console.log("post request url: ", url);
            console.log("post request data: ", content);
            console.log("response: ", data);
            await GetContent();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    return {
        signedIn,
        username,
        soapboxData,
        institution,
        handleSignIn,
        handleSignOut,
        signingIn,
        signInAllowed,
        errorValue,
        setTrackInstallAndVisibility,
        idToken,
        GetContent,
        utcTimeEpoch,
        showTermsAndConditions,
        handleAcceptTerms,
    };
}

export default useAuth;